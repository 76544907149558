import React from 'react'
import styled from 'styled-components'


export const SponsoredRoom = (props) => {

    const {bgImg, title, subtitle} = props

    const Style = styled.div`
        .parallax-bg {
            background: url(${bgImg});
            background-attachment: fixed;
            background-size: cover;
            background-repeat: no-repeat;
            height: 750px;
            position: relative;
        }

        .parallax-body {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            h2, p {
                text-align: center
            }

            h2 {
                font-size: 4rem
            }

            p {
                text-transform: uppercase
            }
        }


    `

    return (
        <Style>
            <section id="deluxe" className="parallax-bg">
                <div className="parallax-body">
                    <h2 className="text-primary"> {title} </h2>
                    <p> {subtitle} </p>
                </div>
            </section>
        </Style>
    )
}
