import React, {useState} from 'react'

const FAQContainer = ({children}) => {
	const [open, setOpen] = useState([])
	const isOpen = index => {
		return open.includes(index) ? true : false;
	};

	const onToggle = index => {
		if (open.includes(index)) {
			setOpen(open.filter(item => item !== index));
		} else {
			setOpen([...open, index]);
		}
	};

	return (
		<dl>
			{React.Children.map(children, (child, index) => {
				return React.cloneElement(child, {
					isOpen: isOpen(index),
					onToggle: () => onToggle(index)
				});
			}
			)}
		</dl>
	);
}

export default FAQContainer
