import React, {useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from './pages/Home';
import Camere from './pages/Camere';
import DoveSiamo from './pages/DoveSiamo';
import FAQ from './pages/FAQ'
import NavigationBar from './components/NavigationBar'
import NoMatch from './pages/NoMatch';
import Footer from './components/Footer';
import ReactGA from 'react-ga'

function App() {

	useEffect(() => {
		ReactGA.initialize('UA-181815244-1')

		ReactGA.pageview(window.location.pathname + window.location.search)
	}, [])

	return (
		<React.Fragment>
			<Router >
				<NavigationBar />
				<Switch>
					<Route exact path='/' component={Home} />
					<Route path='/index.html' component={Home} />
					<Route path='/camere' component={Camere} />
					<Route path='/dovesiamo' component={DoveSiamo} />
					<Route path='/faq' component={FAQ} />
					<Route component={NoMatch} />
				</Switch>
				<Footer />
			</Router>
		</React.Fragment>
	);
}

export default App;
