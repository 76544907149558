import React from 'react'
import styled from "styled-components"
import classNames from 'classnames'
const Answer = ({children, id, isOpen}) => {
	const hidden = !isOpen;


	return (
		<dd>
			<StyledFAQAnswer hidden={hidden} id={id}>
				{children}
			</StyledFAQAnswer>
		</dd>
	)
}

const StyledFAQAnswer = styled.p`
  margin-bottom: 2rem;
  transition: visibility .3s ease;
  font-size: 1.4rem;
  margin-left: 3rem;

  display: ${props => props.hidden ? "none" : 'block'};
  visibility: ${props => props.hidden ? "hidden" : 'visible'};
`

export default Answer;
