import React from 'react'
import styled from "styled-components"
const Question = ({children, isOpen, answerId, onToggle}) => {
	return (<dt>
		<StyledQuestionButtons
			className="FAQ__Question"
			aria-expanded={isOpen}
			aria-controls={answerId}
			onClick={onToggle}
		>
			{children(isOpen, onToggle)}
		</StyledQuestionButtons>


	</dt>);
}

const StyledQuestionButtons = styled.button`
		background: none;
		border: none;
		width: 100%;
		text-align: left;
  font-size: 14px;

  &:hover {
		text-decoration: underline;
		cursor: pointer;
  }
`



export default Question;
