import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Jumbotron, Container, Col, Row } from 'react-bootstrap'
import Aos from 'aos'

export const Header = (props) => {

    const {src, title, subtitle } = props;

    const Style = styled.div`

        .header {
            position: relative;
            height: 650px;
            background: url(${src});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 0;
        }

        .header__body {
            position: absolute;
            bottom: 0;
            background: rgba(255, 255, 255, .6);
        }

    `

    useEffect(() => {
        Aos.init({duration: 1300, delay: 250});
    }, [])


    return (
        <Style>
            <Jumbotron id="header" fluid className="header">
                <Container data-aos="slide-right" className="header__body">
                    <Row>
                        <Col>
                            <div className="text-secondary">
                                <h1>
                                    {title}
                                </h1>
                                <p className="text-capitalize">
                                    {subtitle}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        </Style>
    )
}
