import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components'

const Style = styled.div`
    .text {
        margin: 0;
        font-size: .75rem;
        color: black;
        width: 9rem;
        text-align: center;
    }

    .circle {
        height: 1rem;
        width: 1rem;
        background-color: green;
        border: 1px solid white;
        border-radius: 50%;


        &:hover .container{
            display: block
        }
    }

    .container {
        position: relative;
        display: none;
        animation: all 2s ease;

    }
`

const AnyReactComponent = ({ text }) => <Style><div className="circle"><div className="container"><p className="text">  { text } </p></div></div></Style>;



class MapContainer extends Component {
    static defaultProps = {
        center: {
            lat: 40.627348,
            lng: 14.481818
        },
        zoom: 15
    };

    render() {
        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '250px', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAJnfNB0CIrCBmrV8lN1lz22GfsXN-WVsY" }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    <AnyReactComponent
                        lat={40.627348}
                        lng={14.481818}
                        text="Hotel Dimora Fornillo"
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

export default MapContainer;