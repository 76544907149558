import React, {useEffect} from 'react'
import BaseLayout from '../layouts/BaseLayout'
import {useTranslation} from 'react-i18next';
import headerImage from '../assets/Camere/camera1.jpg'
import {Header} from '../components/Header';
import {ImgTextComponent} from '../components/ImgTextComponent';
import Aos from 'aos'
import {SponsoredRoom} from '../components/SponsoredRoom';
import ServiceComponent from '../components/ServiceComponent';
import FluidLayout from '../layouts/FluidLayout';
import {Row, Col} from 'react-bootstrap';
import Gallery from '../components/Gallery';
import RoomComponent from '../components/RoomComponent';
import BookingForm from '../components/BookingForm'



//Immaggini
import deluxe1 from '../assets/Camere/Deluxe/deluxe1.jpg'
import deluxe2 from '../assets/Camere/Deluxe/deluxe2.jpg'
import deluxe3 from '../assets/Camere/Deluxe/deluxe3.jpg'
import deluxe4 from '../assets/Camere/Deluxe/deluxe4.jpg'
import deluxe5 from '../assets/Camere/Deluxe/deluxe5.jpg'
import deluxe6 from '../assets/Camere/Deluxe/deluxe7.jpg'

import standard1 from '../assets/Camere/Standard/standard1.JPG'
import standard2 from '../assets/Camere/Standard/standard5.JPG'
import standard3 from '../assets/Camere/Standard/standard3.JPG'
import standard4 from '../assets/Camere/Standard/standard4.JPG'

import superior1 from '../assets/Camere/Superior/superior12.JPG'
import superior2 from '../assets/Camere/Superior/superior13.JPG'
import superior3 from '../assets/Camere/Superior/superior11.JPG'
import superior4 from '../assets/Camere/Superior/superior14.JPG'
import superior5 from '../assets/Camere/Superior/superior9.JPG'
import superior6 from '../assets/Camere/Superior/superior10.JPG'
import superior7 from '../assets/Camere/Superior/superior15.JPG'
import superior8 from '../assets/Camere/Superior/superior16.JPG'
import superior9 from '../assets/Camere/Superior/superior17.JPG'

import superiorL1 from '../assets/Camere/SuperiorLarge/superiorl1.JPG'
import superiorL2 from '../assets/Camere/SuperiorLarge/superiorl2.JPG'
import superiorL3 from '../assets/Camere/SuperiorLarge/superiorl3.JPG'
import superiorL4 from '../assets/Camere/SuperiorLarge/superiorl4.JPG'

import superiorg1 from "../assets/Camere/SuperiorGarden/superior4.JPG"
import superiorg2 from "../assets/Camere/SuperiorGarden/superior5.JPG"
import superiorg3 from "../assets/Camere/SuperiorGarden/superior8.JPG"
import superiorg4 from "../assets/Camere/SuperiorGarden/superior6.jpg"
import superiorg5 from "../assets/Camere/SuperiorGarden/superior7.jpg"
import superiorg6 from "../assets/Camere/SuperiorGarden/superior9.jpg"
import superiorg7 from "../assets/Camere/SuperiorGarden/superior10.jpg"

import economy1 from "../assets/Camere/Economy/economy1.jpg"
import economy2 from "../assets/Camere/Economy/economy2.JPG"
import economy3 from "../assets/Camere/Economy/economy3.JPG"
import economy4 from "../assets/Camere/Economy/economy4.JPG"
import economy5 from "../assets/Camere/Economy/economy5.JPG"
import economy6 from "../assets/Camere/Economy/economy6.JPG"

const Camere = (props) => {

	const {t, i18n} = useTranslation();

	useEffect(() => {
		Aos.init({duration: 1300, delay: 200});
	}, [])

	const Deluxeimages = [
		{
			src: deluxe1,
			alt: "Bed",
		},
		{
			src: deluxe2,
			alt: "Interni",
		},
		{
			src: deluxe3,
			alt: "Terzo letto",
		},
		{
			src: deluxe4,
			alt: "Interni",
		},
		{
			src: deluxe5,
			alt: "Interni",
		},
		{
			src: deluxe6,
			alt: "Bagno",
		},
	]


	const StandardImages = [
		{
			src: standard1,
			alt: "Bed",
		},
	
		{
			src: standard3,
			alt: "Terzo letto",
		},
		{
			src: standard4,
			alt: "Interni",
    },
    {
			src: standard2,
			alt: "Interni",
		},
	]

	const EconomyImages = [
		{
			src: economy1,
			alt: "Bed",
		},
	
		{
			src: economy2,
			alt: "Bathroom",
		},
		{
			src: economy3,
			alt: "Interni",
    },

		{
			src: economy5,
			alt: "Interni",
		},
		{
			src: economy6,
			alt: "Garden",
		},
	]


	const SuperiorImages = [
		{
			src: superior1,
			alt: "Bed",
		},
		{
			src: superior2,
			alt: "Interni",
		},
		{
			src: superior3,
			alt: "Terzo letto",
		},
		{
			src: superior4,
			alt: "Interni",
		},
		{
			src: superior5,
			alt: "Interni",
		},
		{
			src: superior6,
			alt: "Interni",
		},
	]

	const SuperiorLImages = [
		{
			src: superiorL1,
			alt: "Bed",
		},
		{
			src: superiorL2,
			alt: "Interni",
		},
		{
			src: superiorL3,
			alt: "Terzo letto",
		},
		{
			src: superiorL4,
			alt: "Interni",
		},
    ]

	const SuperiorGImages = [
		{
			src: superiorg1,
			alt: "Bed",
		},
		{
			src: superiorg2,
			alt: "Interni",
		},
		// {
		// 	src: superiorg3,
		// 	alt: "Terzo letto",
		// },
		{
			src: superiorg4,
			alt: "Interni",
		},
		{
			src: superiorg5,
			alt: "Interni",
		},
		{
			src: superiorg6,
			alt: "Interni",
		},
		{
			src: superiorg7,
			alt: "Interni",
		},
		
	]

	return (
		<React.Fragment>
			<Header src={headerImage} title={t('Homepage.Camere.title')} subtitle={t('Homepage.Camere.subtitle')} />
			<BookingForm translate={t} i18n={i18n} />
			<BaseLayout>
				<ImgTextComponent src={headerImage} title={t('Camere.title')} text={t('Camere.intro')} order={[1, 2]} animations={['fade-right', 'fade-left']} />
			</BaseLayout>
			<ServiceComponent translate={t} />
			<SponsoredRoom bgImg={deluxe4} title={t('Camere.Tipologie.Deluxe.Banner.title')} subtitle={t('Camere.Tipologie.Deluxe.Banner.subtitle')} />
			<FluidLayout classes={['mt-4', 'p-3']}>
				<Row className="align-items-center justify-content-center">
					<Col className="col-md-5 col-12">
						<Gallery imgs={Deluxeimages} />
					</Col>
					<Col className="col-md-5 col-12">
						<h2 className="text-secondary">{t('Camere.Tipologie.Deluxe.Content.title')}</h2>
						<p>{t('Camere.Tipologie.Deluxe.Content.text')}</p>
						<p>{t('Camere.Tipologie.Deluxe.Content.text2')}</p>
					</Col>
				</Row>
			</FluidLayout>
			<RoomComponent id="superiorl" images={SuperiorLImages} title={t('Camere.Tipologie.SuperiorLarge.title')} text={t('Camere.Tipologie.SuperiorLarge.text')} secondary={true} />
			<RoomComponent id="superior" images={SuperiorImages} title={t('Camere.Tipologie.Superior.title')} text={t('Camere.Tipologie.Superior.text')} />
			<RoomComponent id="superiorG" images={SuperiorGImages} title={t('Camere.Tipologie.SuperiorGarden.title')} text={t('Camere.Tipologie.SuperiorGarden.text')} secondary={true} />
			<RoomComponent id="standard" images={StandardImages} title={t('Camere.Tipologie.Standard.title')} text={t('Camere.Tipologie.Standard.text')} />
			<RoomComponent id="economy" images={EconomyImages} title={t('Camere.Tipologie.Economy.title')} text={t('Camere.Tipologie.Economy.text')} secondary={true}/>


		</React.Fragment>
	)
}

export default Camere
