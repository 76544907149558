import React from 'react'


const Item = ({children, isOpen, onToggle}) => {
	return React.Children.map(children, (child, index) => {
		return React.cloneElement(child, {
			isOpen: isOpen,
			onToggle: onToggle
		});
	});
}

export default Item;
