import React, { Fragment } from 'react'
import {useTranslation} from 'react-i18next';
import { Header } from '../components/Header';
import { ImgTextComponent } from '../components/ImgTextComponent';
import BaseLayout from '../layouts/BaseLayout'
import { Row, Col } from 'react-bootstrap';
import BookingForm from '../components/BookingForm'
import ReachUsComponent from '../components/ReachUsComponent';


//Immaggini
import headerImage  from '../assets/Camere/Deluxe/deluxe2.jpg'
import terrazzo from '../assets/Terrazzo/prova.jpg'
import terrazzo2 from '../assets/Camere/Superior/superior9.JPG'

const DoveSiamo = (props) => {

    const { t, i18n } = useTranslation();

    return (
        <Fragment>
            <Header src={ headerImage } title={t('DoveSiamo.title')} subtitle={t('DoveSiamo.subtitle')} />
            <BookingForm translate={t} i18n={i18n}/>
            <BaseLayout>
                <ImgTextComponent src={terrazzo} title={t('DoveSiamo.Intro.title')} text={t('DoveSiamo.Intro.text')} order={[2, 1]} animations={['fade-left', 'fade-right']}/>
                <ImgTextComponent src={terrazzo2} title="" text={t('DoveSiamo.Intro.text2')} order={[1, 2]} animations={['fade-right', 'fade-left']}/>
            </BaseLayout>
            <section className="bg-secondary text-light p-4">
                <BaseLayout>
                    <Row>
                        <Col>
                            <h2 className="text-center">{t('DoveSiamo.Reach.title')}</h2>
                        </Col>
                    </Row>
                   <ReachUsComponent transport={t('DoveSiamo.Reach.Auto.title')} north={t('DoveSiamo.Reach.Auto.nord')} sud={t('DoveSiamo.Reach.Auto.sud')} />
                   <ReachUsComponent transport={t('DoveSiamo.Reach.Treno.title')} north={t('DoveSiamo.Reach.Treno.nord')} sud={t('DoveSiamo.Reach.Treno.sud')} />
                   <ReachUsComponent transport={t('DoveSiamo.Reach.Aereo.title')} north={t('DoveSiamo.Reach.Aereo.text')} sud="" aereo={true} />
                </BaseLayout>  
            </section>
        </Fragment>
    )
}

export default DoveSiamo
