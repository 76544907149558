import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFan, faTv,  faPhone, faWifi, faChild, faDog, faSnowflake, faLock, faWind, faSpa, faHandSparkles, faClock, faArrowRight} from '@fortawesome/free-solid-svg-icons'


const ServiceComponent = (props) => {

    const {translate} = props

    return (
        
        <Row>
            <Col className="col-md-8 col-12 bg-secondary text-light p-5">
                <Row>
                    <Col>
                        <h3>{translate('Camere.Servizi.Camera.title')}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-6 col-12">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item bg-secondary">
                                <FontAwesomeIcon icon={faFan}/> &nbsp;  {translate('Camere.Servizi.Camera.ariaCondizionata')}
                            </li>
                            <li className="list-group-item bg-secondary">
                                <FontAwesomeIcon icon={faTv}/> &nbsp; {translate('Camere.Servizi.Camera.tv')}
                            </li>
                            <li className="list-group-item bg-secondary ">
                                <FontAwesomeIcon icon={faPhone}/> &nbsp;  {translate('Camere.Servizi.Camera.telefono')}
                            </li>
                            <li className="list-group-item bg-secondary">
                                <FontAwesomeIcon icon={faWifi}/> &nbsp;  {translate('Camere.Servizi.Camera.wifi')}
                            </li>
                            <li className="list-group-item bg-secondary">
                                <FontAwesomeIcon icon={faSnowflake}/> &nbsp;  {translate('Camere.Servizi.Camera.minibar')}
                            </li>
                            <li className="list-group-item bg-secondary">
                                <FontAwesomeIcon icon={faLock}/> &nbsp;  {translate('Camere.Servizi.Camera.cassaforte')}
                            </li>
                        </ul>
                    </Col>
                    <Col className="col-md-6 col-12">
                        <ul className="list-group list-group-flush ">
                                <li className="list-group-item bg-secondary">
                                    <FontAwesomeIcon icon={faSpa}/> &nbsp;  {translate('Camere.Servizi.Camera.cortesia')}
                                </li>
                                <li className="list-group-item bg-secondary">
                                    <FontAwesomeIcon icon={faWind}/> &nbsp;  {translate('Camere.Servizi.Camera.asciugacapelli')}
                                </li>
                                <li className="list-group-item bg-secondary">
                                    <FontAwesomeIcon icon={faHandSparkles}/> &nbsp; {translate('Camere.Servizi.Camera.massaggi')}
                                </li>
                                <li className="list-group-item bg-secondary">
                                    <FontAwesomeIcon icon={faChild}/> &nbsp;  {translate('Camere.Servizi.Camera.babysitter')} (extra)
                                </li>
                                <li className="list-group-item bg-secondary">
                                    <FontAwesomeIcon icon={faDog}/> &nbsp;  {translate('Camere.Servizi.Camera.cani')}
                                </li>
                            </ul>
                    </Col>
                </Row>
            </Col>
            <Col  className="col-md-4 col-12 text-center p-5">
                <Row>
                    <Col>
                        <h3>{translate('Camere.Servizi.Info.title')}</h3>
                    </Col>
                </Row>
                <Row>
                    
                        <Col className="col-12 col-md-6">
                            <p><FontAwesomeIcon icon={faArrowRight} size="lg"/><FontAwesomeIcon icon={faClock} size="lg"/></p>
                            <p>Check-In: {translate('Camere.Servizi.Info.checkIn')}</p>
                        </Col>
                        <Col className="col-12 col-md-6">
                            <p><FontAwesomeIcon icon={faClock} size="lg"/><FontAwesomeIcon icon={faArrowRight} size="lg" /></p>
                            <p>Check-Out: {translate('Camere.Servizi.Info.checkOut')}</p>
                        </Col>
                </Row>
                <Row className="mt-5">
                    <Col className="text-center">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item my-0">
                            {translate('Camere.Servizi.Info.parcheggio')}
                            </li>
                            <li className="list-group-item my-0">
                            {translate('Camere.Servizi.Info.deposito')}
                            </li>
                            <li className="list-group-item my-0">
                            {translate('Camere.Servizi.Info.navetta')}
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Col>
        </Row>
        
        
    )
}

export default ServiceComponent
