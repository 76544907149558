import React from 'react'
import { Row, Col } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

export const ImgTextComponent = (props) => {

    const { src, title,  subtitle, text, order, animations, alt } = props;


    return (
        <Row className="align-items-center my-4">
            <Col className={`col-12 col-md-6 order-${order[0]}`}  data-aos={window.matchMedia("(max-width: 700px)").matches ? 'fade-in' : animations[0]} >
                <img className="img-fluid shadow index" src={src} alt={alt}/>
            </Col>
            <Col className={`col-md-6 col-12 order-${order[1]} ${order[1] < order[0] ? "text-md-right text-center" : "text-md-left text-center"} index`}  data-aos={window.matchMedia("(max-width: 700px)").matches ? 'fade-in' : animations[1]} >
                <Row>
                    <Col>
                        <h1 className="text-secondary"> { ReactHtmlParser(title)} </h1>
                        <p className="text-uppercase"> { ReactHtmlParser(subtitle)} </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="text-dark"> { ReactHtmlParser(text)} </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
