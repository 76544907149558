import React from 'react'
import CardComponent from './CardComponent'
import { CardColumns } from 'react-bootstrap'

const CardSet = (props) => {
    return (
        <CardColumns>
            {props.items.map((component, indx) => (
                <CardComponent
                    key={indx}
                    imgSrc={component.img}
                    title={component.title}
                    text={component.text}
                    btnText={component.btnText}
                    variant={component.variant}
                    location={component.location}
                />
            ))



            }
        </CardColumns>
    )
}

export default CardSet
