import React from 'react'
import { Container } from 'react-bootstrap'

const FluidLayout = (props) => {
    return (
        <Container fluid className={props.classes}>
            {props.children}
        </Container >
    )
}

export default FluidLayout
