import React, { useEffect } from 'react'
import {useTranslation} from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import BaseLayout from '../layouts/BaseLayout';
import BookingForm from '../components/BookingForm';
import CardSet from '../components/CardSet';
import CarouselComponent from '../components/CarouselComponent';
import Aos from 'aos'
import 'aos/dist/aos.css'

//Imgs
import slideImg1 from '../assets/Terrazzo/dimoraFornillo.mp4'
import slideImg2 from '../assets/Camere/camera1.jpg'
import slideImg3 from '../assets/SpaziInterni/spaziInterni1.jpg'
import slideImg4 from '../assets/Terrazzo/spiaggia.mp4'
import cardImg1 from '../assets/Camere/Standard/standard1.JPG'
import cardImg2 from '../assets/Camere/Superior/superior1.JPG'
import cardImg3 from '../assets/Camere/Deluxe/deluxe1.jpg'
import { ImgTextComponent } from '../components/ImgTextComponent';


const Home = () => {

    const { t, i18n } = useTranslation();
    
    useEffect(() => {
        Aos.init({duration: 1300, delay: 200});
    }, [])

    const slides = [
        {
            img: slideImg1,
            video: true,
            alt: ' veduta terrazzo',
            title: t('Slides.Homepage.Slide1.title'),
            text: t('Slides.Homepage.Slide1.subtitle'),
            classes: ['text-light', 'bg-secondary', 'rounded']
        },
        {
            img:slideImg2,
            alt: ' veduta terrazzo',
            title: t('Slides.Homepage.Slide2.title'),
            text: t('Slides.Homepage.Slide2.subtitle'),
            classes: ['text-light', 'bg-secondary', 'rounded']
        },
        {
            img: slideImg3,
            alt: ' veduta terrazzo',
            title: t('Slides.Homepage.Slide3.title'),
            text: t('Slides.Homepage.Slide3.subtitle'),
            classes: ['text-light', 'bg-secondary', 'rounded']
        },
        {
            img: slideImg4,
            video: true,
            alt: ' veduta spiaggia',
            title: t('Slides.Homepage.Slide3.title'),
            text: t('Slides.Homepage.Slide3.subtitle'),
            classes: ['text-light', 'bg-secondary', 'rounded']
        },
    ]

    const cards = [
        {
            img: cardImg1,
            title: t('Homepage.Camere.Cards.Card1.title'),
            text: t('Homepage.Camere.Cards.Card1.text'),
            btnText: t('Homepage.Camere.Cards.Card1.btnText'),
            variant: 'primary',
            location: './camere#standard'
        },
        {
            img: cardImg2,
            title: t('Homepage.Camere.Cards.Card2.title'),
            text: t('Homepage.Camere.Cards.Card2.text'),
            btnText: t('Homepage.Camere.Cards.Card2.btnText'),
            variant: 'primary',
            location: './camere#superior'
        },
        {
            img: cardImg3,
            title: t('Homepage.Camere.Cards.Card3.title'),
            text: t('Homepage.Camere.Cards.Card3.text'),
            btnText: t('Homepage.Camere.Cards.Card3.btnText'),
            variant: 'primary',
            location: './camere#deluxe'
        },
    ]

    return (
        <React.Fragment>
            <CarouselComponent 
                items={slides}
            />
            <BookingForm translate={t} i18n={i18n}/>
            <BaseLayout classes={['mt-4']}>
                <ImgTextComponent src={slideImg3} order={[1,2]} title={"Hotel Dimora Fornillo"} text={t('Homepage.intro')} animations={["fade-right", "fade-left"]} />
                <Row data-aos="fade-up" className="text-center mt-5">
                    <Col>
                        <h2 className="text-secondary">{t('Homepage.Camere.title')}</h2>
                        <p className="text-uppercase"> {t('Homepage.Camere.subtitle')} </p>
                        <p> {t('Homepage.Camere.text')} </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CardSet items={cards}/>
                    </Col>
                </Row>
            </BaseLayout>
        </React.Fragment>
    );

}
export default Home;