import React from 'react'
import BaseLayout from '../layouts/BaseLayout'
import { Row, Col } from 'react-bootstrap'
import Gallery from './Gallery'
import ReactHtmlParser from 'react-html-parser'

const RoomComponent = (props) => {

    const {title, text, images, secondary, id } = props

    return (
        <section id={id} className={secondary ? "bg-secondary text-light p-3" : "p-3"}>
            <BaseLayout>
                <Row>
                    <Col className="col-12 text-center">
                        <h2 className={secondary ? "text-light" : "text-secondary"}> {title} </h2>
                        <p> { ReactHtmlParser(text)} </p>
                    </Col>
                </Row>
                <Gallery imgs={images} />
            </BaseLayout>
        </section>
        
    )
}

export default RoomComponent
