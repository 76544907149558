import React from 'react'
import BaseLayout from '../layouts/BaseLayout'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next';
import  MapContainer  from './MapContainer';
import { faPhone, faEnvelope, faMobileAlt} from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HashLink as Link } from 'react-router-hash-link'
import FluidLayout from '../layouts/FluidLayout';




const Footer = (props) => {


    const { t } = useTranslation();

    const Style = styled.div`
        .footer__list {
            padding-left: 0;
            list-style-type: none;
            text-align: left;
        }

    `

    return (
        <Style>
            <footer className="bg-secondary text-light text-center  pt-3 pb-3">
                <BaseLayout>
                    <Row>
                        <Col className="col-md-4 col-12">
                            <h4> {t('Footer.Col1.title')} </h4>
                            <ul className="footer__list">
                                <li><Link className="text-light" to="/">Homepage</Link></li>
                                <li><Link className="text-light" to="/camere">{t('Navbar.camere')}</Link></li>
                                <li><Link className="text-light" to="/dovesiamo">{t('Navbar.dovesiamo')}</Link></li>
                            </ul>
                        </Col>
                        <Col className="col-md-4 col-12">
                            <h4> {t('Footer.Col2.title')} </h4>
                            <ul className="footer__list">
                                <li><FontAwesomeIcon icon={faPhone} /> &nbsp; <a className="text-light" href="tel:0039089811442"> (+39) 089 811 422</a></li>
                                <li><FontAwesomeIcon icon={faMobileAlt} /> &nbsp; <a className="text-light" href="tel:00393664857775"> (+39) 366 485 7775 </a> </li>
                                <li> <FontAwesomeIcon icon={faEnvelope} /> &nbsp; <a className="text-light" href="mailto:info@hoteldimorafornillo.it">info@hoteldimorafornillo.it</a> </li>
                                <li> <FontAwesomeIcon icon={faFacebookF} /> &nbsp; <a className="text-light" href="https://www.facebook.com/sharer.php?u=http%3A%2F%2Fwww.hoteldimorafornillo.it"> Hotel Dimora Fornillo </a> </li>
                            </ul>
                        </Col>
                        <Col className="col-md-4 col-12">
                            <h4> {t('Footer.Col3.title')} </h4>
                            <MapContainer />
                        </Col>
                    </Row>
                </BaseLayout>
                <FluidLayout>
                    <Row className="mt-3">
                        <Col>
                            <p className="text-light text-center">Credits Dimora Fornillo Positano | P.IVA 08354391214 | Made With <span role="img" aria-label="Hearth Emoji">❤️</span> By <a className="text-light" href="https://www.stayerk.me"><strong>Andrea Ercolino</strong></a> for Dimensione Servizi di Luigi Ercolino</p>
                        </Col>
                    </Row>
                </FluidLayout>
            </footer>
        </Style>
    )
}

export default Footer
