import React, {useEffect} from 'react'
import FluidLayout from '../layouts/FluidLayout'
import { Row, Col } from 'react-bootstrap'
import DateInput from './DateInput'
import styled from 'styled-components'
import Aos from 'aos'

const BookingForm = (props) => {

    useEffect(() => {
        Aos.init({duration: 1300, delay: 200});
    }, [])

    const { translate, i18n } = props

    const Style = styled.div`
        .half-lenght {
            width: 100%;
            z-index: 29;
        }

        @media (min-width: 992px) {
            .half-lenght {
                width: 50%
            }
        }

        @media (min-width: 768px) {
            .half-lenght {
                width: 60%
            }
        }


    `

    if( window.matchMedia("(max-width: 700px)").matches){ 
        return (
            <Style>
                <FluidLayout classes={['bg-secondary', 'text-light', 'p-3']}>
                    <Row className="align-items-center half-lenght  mr-auto" >
                        <Col className="col-12 col-md-3">
                            <h4 className="d-block"> {translate('Booking.formHeading')} </h4>
                        </Col>
                        <Col className="col-12 col-md-9 ml-auto">
                            <DateInput translate={translate} />
                        </Col>
                    </Row>
                </FluidLayout>
            </Style>
            )}
    else {
        return (
            <Style>
                <FluidLayout classes={['bg-secondary', 'text-light', 'p-3']}>
                    <Row className="align-items-center half-lenght  mr-auto" >
                        <Col className="col-12 col-md-3">
                            <h4 className="d-block"> {translate('Booking.formHeading')} </h4>
                        </Col>
                        <Col className="col-12 col-md-9 ml-auto">
                            <DateInput translate={translate} i18n={i18n}/>
                        </Col>
                    </Row>
                </FluidLayout>
            </Style>
            )}

}

export default BookingForm
