import React, { Component } from 'react'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { Row, Col } from 'react-bootstrap';
import SubmitButton from './SubmitButton';
import moment from 'moment';
import 'moment/locale/it'
import { useTranslation } from 'react-i18next';

export default class DateInput extends Component {



    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
        }
    }



    componentDidMount() {
        /*const {i18n} = useTranslation;
        if (i18n.language === 'it') {
            moment.locale('it');
        } else {
            moment.locale('en');
        }*/
        moment.locale('it')

    }




    render() {
        const orientation = window.matchMedia("(max-width: 700px)").matches ? 'vertical' : 'horizontal'


        return (
            <Row className="align-items-center index">
                <Col className>
                    <DateRangePicker
                        orientation={orientation}
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })} // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                        monthFormat="MMMM YYYY"
                        startDatePlaceholderText={this.props.translate('Booking.startDate')}
                        endDatePlaceholderText={this.props.translate('Booking.endDate')}

                    />
                </Col>
                <Col>
                    <SubmitButton id="68472D57C97F1582" translate={this.props.translate} start={this.state.startDate ? this.state.startDate.format().slice(0, 10) : this.state.startDate} end={this.state.endDate ? this.state.endDate.format().slice(0, 10) : this.state.endDate} />
                </Col>
            </Row>
        )
    }


}

