import React, {useEffect} from 'react'
import BaseLayout from '../layouts/BaseLayout'
import FAQContainer from '../components/FAQContainer'
import Item from '../components/Item'
import Question from '../components/Question'
import Answer from '../components/Answer'
import {useTranslation} from "react-i18next"
import {Header} from "../components/Header"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faWater,  faUmbrellaBeach, faPaw, faLuggageCart, faParking, faPlusCircle, faMinusCircle} from '@fortawesome/free-solid-svg-icons'

import headerImage from '../assets/Terrazzo/prova.jpg'

const FAQ = (props) => {
	const {t} = useTranslation()

  return (
    <>
		<Header src={headerImage} title={t('FAQ.title')} subtitle={t('FAQ.subtitle')} />
		<BaseLayout classes={["mt-5"]}>
			<FAQContainer>
				<Item>
					<Question answerId="q1">
						{(isOpen, onToggle) => {
							return (
								<>
                  <h2 className="text-primary">{isOpen ? <span className="text-secondary"><FontAwesomeIcon icon={faMinusCircle}/></span> : <span className="text-secondary"> <FontAwesomeIcon icon={faPlusCircle}/></span>} &nbsp; <FontAwesomeIcon icon={faMapMarkerAlt}/> &nbsp; {t("FAQ.domanda1")} </h2>
								</>
							);
						}}
					</Question>
					<Answer id="q1">{t("FAQ.risposta1")}</Answer>
				</Item>
				<Item>
					<Question answerId="q2">
						{(isOpen, onToggle) => {
							return (
								<>
									<h2 className="text-primary">{isOpen ? <span className="text-secondary"><FontAwesomeIcon icon={faMinusCircle}/></span> : <span className="text-secondary"> <FontAwesomeIcon icon={faPlusCircle}/></span>} &nbsp; <FontAwesomeIcon icon={faWater}/> &nbsp;{t("FAQ.domanda2")}</h2>
								</>
							);
						}}
					</Question>
					<Answer id="q2">{t("FAQ.risposta2")}!</Answer>
				</Item>
				<Item>
					<Question answerId="q3">
						{(isOpen, onToggle) => {
							return (
								<>
                  <h2 className="text-primary">{isOpen ? <span className="text-secondary"><FontAwesomeIcon icon={faMinusCircle}/></span> : <span className="text-secondary"> <FontAwesomeIcon icon={faPlusCircle}/></span>} &nbsp; <FontAwesomeIcon icon={faUmbrellaBeach}/> &nbsp;{t("FAQ.domanda3")}</h2>
								</>
							);
						}}
					</Question>
					<Answer id="q3">{t("FAQ.risposta3")}!</Answer>
				</Item>
				<Item>
					<Question answerId="q4">
						{(isOpen, onToggle) => {
							return (
								<>
                  <h2 className="text-primary">{isOpen ? <span className="text-secondary"><FontAwesomeIcon icon={faMinusCircle}/></span> : <span className="text-secondary"> <FontAwesomeIcon icon={faPlusCircle}/></span>} &nbsp; <FontAwesomeIcon icon={faLuggageCart}/> &nbsp; {t("FAQ.domanda4")}</h2>
								</>
							);
						}}
					</Question>
					<Answer id="q4">{t("FAQ.risposta4")}!</Answer>
				</Item>
				<Item>
					<Question answerId="q5">
						{(isOpen, onToggle) => {
							return (
								<>
                  <h2 className="text-primary">{isOpen ? <span className="text-secondary"><FontAwesomeIcon icon={faMinusCircle}/></span> : <span className="text-secondary"> <FontAwesomeIcon icon={faPlusCircle}/></span>} &nbsp; <FontAwesomeIcon icon={faPaw}/> &nbsp;{t("FAQ.domanda5")}</h2>
								</>
							);
						}}
					</Question>
					<Answer id="q5">{t("FAQ.risposta5")}!</Answer>
				</Item>
				<Item>
					<Question answerId="q6">
						{(isOpen, onToggle) => {
							return (
								<>
									<h2 className="text-primary">{isOpen ? <span className="text-secondary"><FontAwesomeIcon icon={faMinusCircle}/></span> : <span className="text-secondary"> <FontAwesomeIcon icon={faPlusCircle}/></span>} &nbsp; <FontAwesomeIcon icon={faParking}/> &nbsp;{t("FAQ.domanda6")}</h2>
								</>
							);
						}}
					</Question>
					<Answer id="q6">{t("FAQ.risposta6")}!</Answer>
				</Item>


			</FAQContainer>
    </BaseLayout >
      </>
	)
}

export default FAQ
