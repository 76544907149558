import React from 'react'
import styled from 'styled-components'
import { Navbar, Nav, NavItem } from 'react-bootstrap'
import { HashLink as Link } from 'react-router-hash-link'
import { useTranslation } from 'react-i18next'
import  itaFlag from '../assets/italy.svg'
import  ukFlag from '../assets/uk.svg'
import  logo from '../assets/logo.png'


const Styles = styled.div`

    .no-underline {
        text-decoration: none;

        &:hover {
            text-decoration: none;            
        }
    }

    .flagBtn{
        border: none;
        background: none;
    }

    .flagImage{
        width: 1.650rem;
    }

    .index {
        z-index: 30
    }

    .logo {
        width: 220px;
    }


`

const NavigationBar = () => {

    const { t, i18n } = useTranslation();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    }

    return (
        <Styles>
            <Navbar expand="lg" className="bg-light text-secondary index" fixed="top" >
                <Navbar.Brand className="no-underline m-0">
                    <Link to="/">
                        <img src={logo} className="img-fluid logo" alt="logo" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navigation" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto text-center">
                        <Nav.Item>
                                <Link className="no-underline nav-link" to="/">Homepage</Link>
                        </Nav.Item>
                        <NavItem>
                                <Link className="no-underline nav-link" to="/camere#header">{t('Navbar.camere')}</Link>
                        </NavItem>
                        <NavItem className="mr-2">
                                <Link className="no-underline nav-link"  to="/dovesiamo#header">{t('Navbar.dovesiamo')}</Link>
                        </NavItem>
                        <NavItem className="mr-2">
                                <Link className="no-underline nav-link"  to="/faq#header">{t('Navbar.faq')}</Link>
                        </NavItem>
                    </Nav>
                    <Nav className="ml-auto d-flex flex-row justify-content-center ">
                        <Nav.Item>
                            <button 
                                className="flagBtn d-block"
                                onClick={() => changeLanguage('it')}
                            >
                            <img alt={t('Navbar.lingua1')} src={itaFlag} className="flagImage"/>
                            </button>
                        </Nav.Item>
                        <Nav.Item className="mr-3">
                            <button
                                className="flagBtn d-block"
                                onClick={() => changeLanguage('en')}
                            >
                            <img alt={t('Navbar.lingua2')} src={ukFlag} className="flagImage"/>    
                            </button>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Styles>
    )
}

export default NavigationBar;
