import React from 'react'
import { Carousel } from 'react-bootstrap'
import styled from 'styled-components'


const CarouselComponent = (props) => {

    const Style = styled.div`
        .carousel-img {
            height: 750px;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }

        .carousel-caption__bg {
            backdrop-filter: blur(3px);
            border-radius: 5px;
            background: rgba(4, 173, 191, .6);
            max-width: 500px;
            margin: 0 auto
        }

        .carousel-caption__body {
            opacity: 1;
            color: white;
        }
    
    `

    
    return (
        <Style>
            <Carousel interval={2400}>
                {props.items.map((component, indx) =>
                    (<Carousel.Item key={indx} className=''>
                        {component.video ? <video
                            className="carousel-img d-block"
                            muted
                            autoPlay
                            loop={true}
                            src={component.img}
                        /> : <img
                                className="carousel-img d-block"
                                src={component.img}
                                alt={component.alt}
                            />}
                        <Carousel.Caption className="carousel-caption__bg">
                            <div className="carousel-caption__body">
                                <h3>{component.title}</h3>
                                <p> {component.text} </p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>)
                )}
            </Carousel>
        </Style>
    )
}

export default CarouselComponent
