import React, { Fragment } from 'react'
import ReactHtmlParser from 'react-html-parser'
import {Row, Col} from 'react-bootstrap'
import {useTranslation} from 'react-i18next';

const ReachUsComponent = (props) => {

    const {  transport, sud, north, aereo } = props;
    const { t } = useTranslation();

    const divAereo = <Col> {ReactHtmlParser(north)}  </Col> 
    const divAltro = <> <Col> <h4> {t('DoveSiamo.Reach.nord')} </h4> <p> {ReactHtmlParser(north)} </p> </Col> <Col> <h4> {t('DoveSiamo.Reach.sud')} </h4> <p> {ReactHtmlParser(sud)} </p> </Col> </>;

    return (
        <Fragment>
            <Row className="mt-3">
                <Col>
                    <h3 className="text-center"> {transport} </h3>
                </Col>
            </Row>
            <Row>
                {aereo ? divAereo :  divAltro}
            </Row>
        </Fragment>
    )
}

export default ReachUsComponent
