import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


export default class Gallery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
            images: []
        };
    }

    extractSrcs(imgs, extracted) {
        imgs.map((element) => {
            extracted.push(element.src)
        }) 
    }


    componentDidMount = function() {
            let extracted = []
            this.extractSrcs(this.props.imgs, extracted)
            this.setState({
                images: extracted
            })
        }

    render() {

        const { photoIndex, isOpen, images } = this.state;


        return (
            <React.Fragment>
                <Row>
                    {this.props.imgs.map((element, indx) => (
                        <Col key={indx} className="mb-4 col-12 col-sm-6 col-md-4">
                            {element.video ? <video muted autoplay loop={true} className="img-fluid shadow" src={element.src} /> : <img className="img-fluid shadow" src={element.src} alt={element.alt} onClick={() => this.setState({ isOpen: true, photoIndex: indx })} />}
                        </Col>
                    ))}
                </Row>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
            </React.Fragment>
        )
    }
}

