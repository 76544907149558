import React from 'react'
import { Container } from 'react-bootstrap'

const BaseLayout = (props) => {
    return (
        <Container className={props.classes} >
            {props.children}
        </Container>
    )
}

export default BaseLayout
