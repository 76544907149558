import React, { Component } from 'react'

export default class SubmitButton extends Component {


    render() {
        return (
            <a className="btn btn-success mx-3" href={`https://booking.ericsoft.com/BookingEngine/Book?idh=${this.props.id}&lang=it&cur=EUR&arrival=${this.props.start}&departure=${this.props.end}`}>
                {this.props.translate("Booking.formTitle")}
            </a >
        )
    }
}
