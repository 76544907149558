import React from 'react'
import FluidLayout from '../layouts/FluidLayout'
import {Row, Col } from 'react-bootstrap'
import {useTranslation} from 'react-i18next';
import { Header } from '../components/Header';
import headerImage from '../assets/Terrazzo/prova.jpg'

const NoMatch = () => {

    const { t } = useTranslation();

    return (
        <FluidLayout>
            <Row>
                <Col>
                    <Header title="Error 404" subtitle={t('NoMatch.title')} src={headerImage}/>
                </Col>
            </Row>
        </FluidLayout>
    )
}

export default NoMatch
