import React from 'react'
import { Card } from 'react-bootstrap'
import { HashLink as Link } from 'react-router-hash-link'


const CardComponent = (props) => {

    const { title, text, btnText, variant, imgSrc, location} = props

    return (
            <Card>
                <Card.Img variant="top" src={imgSrc} />
                <Card.Body>
                    <Card.Title> {title} </Card.Title>
                    <Card.Text> {text} </Card.Text>
                    <Link className={`btn btn-${variant}`} to={location}> {btnText} </Link>
                </Card.Body>
            </Card>
    )
}

export default CardComponent
